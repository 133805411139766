import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FirebaseAuthService } from '../../providers/firebase-auth.service';
import { UploadFileService } from '../../providers/upload-file.service';
import { UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-renderlimit-select',
  templateUrl: './renderlimit-select.component.html',
  styleUrls: ['./renderlimit-select.component.scss']
})
export class RenderlimitSelectComponent {
  @Input() inputMaxElementsInRow: number = 5;
  @Output() renderLimitChange = new EventEmitter<number>();
  renderLimitOptions = [10, 25, 50, 100, 9999]; // Standard options
  selectedRenderLimit: number = 25; // Default to 25
  public isMobile: boolean = environment.type === 'mobile';

  constructor(
    public firebaseAuth: FirebaseAuthService,
    public uploadService: UploadFileService
  ) {
    // Subscribe to the service to get the current render limit
    if (this.firebaseAuth.currentUser?.renderLimit !== undefined) {
      this.selectedRenderLimit = this.firebaseAuth.currentUser.renderLimit;
    }
    // You may also subscribe to changes in currentUser if needed
    this.firebaseAuth.firebaseUser.subscribe(user => {
      if (this.firebaseAuth.currentUser?.renderLimit !== undefined) {
        this.selectedRenderLimit = this.firebaseAuth.currentUser.renderLimit;
      }
    });
  }

  getCurrentUploadingCards() {
    return this.uploadService.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING})?.length;
  }

  setRenderLimit(limit: number) {
    if (limit === 9999 || limit >= this.firebaseAuth.currentUser.renderModelsPerCycle) {
      this.selectedRenderLimit = limit;
      this.renderLimitChange.emit(limit);
    }
  }

  isSelected(limit: number) {
    return this.selectedRenderLimit === limit;
  }

}
