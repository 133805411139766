import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { UploadTask, StorageReference } from 'firebase/storage';

export enum UPLOAD_STATUS {
  INPROGRESS = 'inprogress',
  PROCESSING = 'processing',
  ERROR = 'error',
  UPLOADED = 'uploaded',
  STOPPED = 'stopped'
}

export interface IGroupedCard {
  id: string;
  previewImageMobile: string;
  previewImageWeb: string;

  previewVideoMobile: string;
  previewVideoWeb: string;

  title: string;
  details?: string;
  showUploadBtn?: boolean;
  linkedCollectionId?: string;
  fileName: string;
  uploadStatus?: {
    status: UPLOAD_STATUS;
    progress: number;
    spinnerMode: ProgressSpinnerMode;
    progressMsg: string;
    progressStats: string;
    uploadTaskLink?: UploadTask;
    uploadFileRef?: StorageReference;
    fileLink?: File;
  };
  modifiedDate?: number;
}

export interface IUploadCardEvent {
  file: File;
  cardId: string;
  collectionId?: string;
}
