import { SettingsService } from './shared/providers/settings.service';
import { CardsService } from 'src/app/shared/providers/cards.service';
import { Component, HostListener, AfterViewInit, NgZone, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, RouterEvent, NavigationEnd, RouterOutlet } from '@angular/router';
import { CustomIconsRegistryService } from './shared/providers/custom-icons-registry.service';
import { slideInAnimation } from './shared/providers/animations-list';



// import { Device } from '@capacitor/device';
// import { SplashScreen } from '@capacitor/splash-screen';
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture';
import { MatSnackBarContainer } from '@angular/material/snack-bar';
import { UserDataService } from './shared/providers/user-data.service';
import { CustomRollbar, RollbarService } from './shared/providers/rollbarlog.service';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent implements OnInit, AfterViewInit {
  private appStateListenerAdded = false; // Flag to ensure listener is added only once

  public isSideNavOpened: boolean;
  public oldPartApp: boolean;
  public pageBreadcrumbs: boolean;

  private swipeCoord?: [number, number];
  private swipeTime?: number;

  constructor(public zone: NgZone,
              private router: Router,
              private CardService: CardsService,
              public settingsService: SettingsService,
              public userDataService: UserDataService,
              private matIconRegistry: MatIconRegistry,
              @Inject(RollbarService) private rollbar: CustomRollbar,
              iconsService: CustomIconsRegistryService,
    ) {

      (window as any).angularAppComponentRef = {
        zone: this.zone,
        intercomExternalInit: () => this.intercomExternalInit(),
        component: this
      };


      iconsService.registerIcons();

      this.initializeApp();
    }


  title = '3DWay';

  ngOnInit() {
    this.addPlatformClassToHtml();
    this.addCssVariablesToDocument();

    this.CardService.initStartTime = performance.now();
    console.log('PERF_ INIT START TIME:', this.CardService.initStartTime);
    // smoothscroll.polyfill();

    SuppressLongpressGesture.activateService();

    // this.firebaseAuth.firebaseSignInAnonymously();

    this.addAppStateListener();

    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.oldPartApp = this.router.url === '/' || this.isExistInUrl('/ourway') || this.isExistInUrl('/solutions')  || this.isExistInUrl('/pricing') || this.isExistInUrl('/sales') || this.isExistInUrl('/support') || this.isExistInUrl('/legal');
        this.pageBreadcrumbs = this.isExistInUrl('/scene') || this.isExistInUrl('/home');
      }
    });
    this.CardService.initHomePageStartTime = performance.now();
    console.log('PERF_ INIT HOME START TIME:', this.CardService.initHomePageStartTime);
    this.CardService.collectionFeedInit();

    // DIRTY-WORKAROUND TO PATCH ANGULAR ANIMATION;
    const c: any = MatSnackBarContainer.ɵcmp as any;
    const d = c.data.animation[0];
    const e = c.data.animation[0].definitions[0].styles.styles.transform = `scale(${this.settingsService.snackBarAppearScale})`;
    c.data.animation[0].definitions[3].animation.timings = `${this.settingsService.snackBarHideTiming}ms cubic-bezier(0.4, 0.0, 1, 1)`;
    c.data.animation[0].definitions[2].animation.timings = `${this.settingsService.snackBarAppearTiming}ms cubic-bezier(0, 0, 0.2, 1)`;

    this.matIconRegistry.getNamedSvgIcon('checkbox-thin-icon').subscribe();;
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          const slug = event.url.split('.io').pop();
          if (slug) {
              this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
  });
  }

  addAppStateListener() {
    if (!this.appStateListenerAdded) {
      App.addListener('appStateChange', (state: AppState) => {
        if (state.isActive) {
          this.CardService.triggerAppActivated();
        }
        console.log('App state changed. Is active: ', state.isActive);
      });
      this.appStateListenerAdded = true; // Mark listener as added
    }
  }

  ngAfterViewInit() {
  }

  public _window(): any {
    // return the global native browser window object
    return window;
   }

  private addPlatformClassToHtml(): void {
    const platform = environment.type
    window.document.documentElement.classList.add(platform);
  }

  private addCssVariablesToDocument(): void {
    window.document.documentElement.style.setProperty('--keyboard-height', '0px');

    const barTiming: number = this.settingsService.cardStartDelayTime;
    const hideTiming: number = this.settingsService.cardStartDelayTime + this.settingsService.progressBarHideDelay;
    const wrapperAnimationTiming: number = this.settingsService.wrapperAnimationTime;
    const wrapperAnimationDelay: number = this.settingsService.wrapperAnimationDelay;
    const titleAnimationTiming: number = this.settingsService.titleAnimationTime;
    const titleAnimationDelay: number = this.settingsService.titleAnimationDelay;
    const descAnimationTiming: number = this.settingsService.descriptionAnimationTime;
    const descAnimationDelay: number = this.settingsService.descriptionAnimationDelay;
    window.document.documentElement.style.setProperty('--progress-bar-timing', barTiming +'ms');
    window.document.documentElement.style.setProperty('--progress-bar-hide-time', hideTiming + 'ms');
    window.document.documentElement.style.setProperty('--card-wrapper-animation-time', wrapperAnimationTiming + 'ms');
    window.document.documentElement.style.setProperty('--card-wrapper-animation-delay', wrapperAnimationDelay + 'ms');
    window.document.documentElement.style.setProperty('--card-title-animation-time', titleAnimationTiming + 'ms');
    window.document.documentElement.style.setProperty('--card-title-animation-delay', titleAnimationDelay + 'ms');
    window.document.documentElement.style.setProperty('--card-description-animation-time', descAnimationTiming + 'ms');
    window.document.documentElement.style.setProperty('--card-description-animation-delay', descAnimationDelay + 'ms');

    window.document.documentElement.style.setProperty('--video-resize-type', this.settingsService.videoResizeType);

    window.document.documentElement.style.setProperty('--approot-background', 'black');
    window.document.documentElement.style.setProperty('--mainbtn-nav-shift', '0px');
    window.document.documentElement.style.setProperty('--mainbtn-card-shift', '0px');



  }

  private isExistInUrl(route: string): boolean {
    return this.router.url.indexOf(route) >= 0;
  }

  onDragOver(event: DragEvent) {
    this.userDataService.dragOver = true;
  }

  public intercomExternalInit() {
    // START INTERCOM

    // this.zone.run(() => {
    //   window.Intercom('boot', {
    //     app_id: environment.config.intercomAppId
    //   });
    //   this.router.events.subscribe((event: RouterEvent) => {
    //     if (event instanceof NavigationEnd) {
    //       window.Intercom('update');
    //     }
    //   });
    // });
  }

  public onSideNavStateChanged(event) {
    this.isSideNavOpened = event;
    console.log(event);
  }


  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }


}
