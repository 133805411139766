import { INavpanelAction, SUBSCRIPTION_STATUS, UploadModelCard } from './../../models/card';
import { FirebaseAuthService } from 'src/app/shared/providers/firebase-auth.service';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { IGroupedCard, IUploadCardEvent, UPLOAD_STATUS } from 'src/app/models/groupedCard';
import { environment } from 'src/environments/environment';
import { SettingsService } from './settings.service';
import { CardsService } from './cards.service';
import { RollbarService, CustomRollbar } from './rollbarlog.service';
import { StopRenderSnackBarComponent } from '../stop-render-snack-bar/stop-render-snack-bar.component';
import { UtilityFunctionsService } from './utility-functions.service';
import { UploadMetadata, UploadTask, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { FirebaseError } from 'firebase/app';
import { MobileCommonService } from '../mobile/mobileCommon.service';


export interface IUploadProgress {
  itemId: string;
  progress: number;
  bytesTransfered: number;
  bytesTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  public uploadModels: IGroupedCard[] = [];

  public firebaseUploadingItem: IUploadProgress[] = [];
  public itemsTotalProgressSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  // web render limit check located in web-upload service
  public mobileRenderLimitTriggerSubject: Subject<void> = new Subject();
  public latestUploadedModelSubscription: Subject<IGroupedCard> = new Subject();

  public uploadErrorSubscription: Subject<void> = new Subject();

  public uploadStatusSubscription: Subject<boolean> = new Subject();

  private lastFileName: string;

  get currentNumberOfUploadingOrRenderingModels(): number {
    return this.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING})?.length;
  }


  constructor(
    @Inject(RollbarService) private rollbar: CustomRollbar,
    public firebaseAuth: FirebaseAuthService,
    public commonMobileService: MobileCommonService,
    public settingService: SettingsService,
    public cardService: CardsService,
    public utilityService: UtilityFunctionsService
    ) {
      this.initializeFirebaseUserSubscription();
    }

  private initializeFirebaseUserSubscription(): void {
    this.firebaseAuth.firebaseUser.subscribe(user => {
      if (user) {
        this.observeFirebaseModelsWithPreprocessing();
      }
    });
  }

  private observeFirebaseModelsWithPreprocessing(): void {
    this.firebaseAuth.getFirebaseModelsWithPreprocessingStatusUpdates().subscribe(dataArray => {
      console.log('check current processing models in firebase: ', dataArray);
      const sortedArray = dataArray.sort((a, b) => a.modifiedDate - b.modifiedDate);
      sortedArray.forEach(data => this.processDataItem(data));
    });
  }

  private processDataItem(data): void {
    const mockUploadingCard = this.utilityService.convertFirebaseDtoToGroupedCard(data);
    const cardIndex = this.uploadModels.findIndex(card => card.id === mockUploadingCard.id);
    if (cardIndex === -1) {
      this.uploadModels.unshift(mockUploadingCard);
      this.handleModelProcessing(mockUploadingCard);
    }
  }

  // SEND REQUEST FOR UPLOADING (SHOULD BE RENAMED)
  public async requestFileUpload(file: File, collectionId?: string): Promise<void> {

    if (this.shouldRejectUpload()) {
      this.utilityService.uploadServiceNotification('You have reached the limit of uploaded models', 3000);
      return;
    }
    if(this.renderLimitCheckNotPassed()) {
      const isRenderLimitIncreased = await this.commonMobileService.requestIncreasingRenderLimit();
      if(isRenderLimitIncreased) {
        console.log('render limit increased, upload started');
        this.uploadToFirebase(file, collectionId);
      }
      return
    }
    console.log('UPLOAD STARTED');
    this.uploadToFirebase(file, collectionId);
  }

  private shouldRejectUpload(): boolean {
    return this.firebaseAuth.currentUser.subscriptionStatus !== SUBSCRIPTION_STATUS.SUBSCRIBER && this.uploadModels.length > 2;
  }



  public webUploadToFirebase(file: File, custompath: string, collectionToUploadId?: string): void {
    const pathParts = custompath.split('/'); // Splitting the path by '/'
    const filenameWithExtension = pathParts.pop() || ''; // Getting the last part, the filename with extension
    const filenameWithoutExtension = filenameWithExtension.split('.')[0]; // Removing the extension

    const generatedUploadItem: IGroupedCard = this.utilityService.generateMockedUploadItem(filenameWithoutExtension);
    const uploadItemId = generatedUploadItem.id;
    this.uploadModels.unshift(generatedUploadItem);
    this.startFirebaseFileUploadInternal({
      file,
      cardId: uploadItemId,
      collectionId: collectionToUploadId,
   }, custompath);
    console.log('webUploadToFirebase');
  }

  private uploadToFirebase(file: File, collectionId?: string): void {
    const generatedUploadItem: IGroupedCard = this.utilityService.generateMockedUploadItem(this.lastFileName);
    const uploadItemId = generatedUploadItem.id;
    this.uploadModels.unshift(generatedUploadItem);

    const extension = file.name.split('.').pop();
    const customPath = generatedUploadItem.fileName + '/' + generatedUploadItem.fileName + '.' + extension;

    this.startFirebaseFileUploadInternal({
       file,
       cardId: uploadItemId,
       collectionId,
    }, customPath);
  }

  private startFirebaseFileUploadInternal(uploadEvt: IUploadCardEvent, customPath?: string): void {
    this.firebaseAuth.updateNumberOfUploads();

    const uploadItemId = uploadEvt.cardId;
    const file = uploadEvt.file;

    const uploadCardItem = this.uploadModels.find((item) => {
      return item.id === uploadItemId
    })

    console.log('uploadFileName: ', uploadCardItem.fileName);
    // const storageRef = firebase.default.storage().ref().child(`uploaded_models/${this.firebaseAuth.currentUser.uid}/${customPath}/${fileName}`);



    const storage = getStorage();
    const storageRef = ref(storage, `uploaded_models/${this.firebaseAuth.currentUser.uid}/${customPath}`);

    uploadCardItem.uploadStatus.uploadFileRef = storageRef;
    const meta: UploadMetadata = {customMetadata: {framesToRender: this.settingService.frameNumberToRender, parentCollectionId: uploadEvt.collectionId}};

    const uploadTask: UploadTask = uploadBytesResumable(storageRef, uploadEvt.file, meta);
    uploadCardItem.uploadStatus.uploadTaskLink = uploadTask;

    uploadCardItem.linkedCollectionId = uploadEvt.collectionId;
    uploadCardItem.uploadStatus.status = UPLOAD_STATUS.INPROGRESS;
    uploadCardItem.uploadStatus.progressMsg = 'Uploading... ';


    this.rollbar.info(`Upload ${this.firebaseAuth.currentUser.subscriptionStatus} started: ${this.firebaseAuth.currentUser.uid}/${customPath}`);

    uploadCardItem.uploadStatus.uploadTaskLink.on('state_changed',
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 10;

        this.countTotalUploadProgress({
          itemId: uploadItemId,
          progress,
          bytesTransfered: snapshot.bytesTransferred,
          bytesTotal: snapshot.totalBytes,
        })
        uploadCardItem.uploadStatus.progress = progress
        uploadCardItem.uploadStatus.spinnerMode = 'determinate';

        this.uploadStatusSubscription.next(true);
        console.log('Upload is ' + progress + '% done');

      },
      (error: FirebaseError) => {
        // Reminder
        // https://firebase.google.com/docs/storage/web/handle-errors
        console.log(error);
        this.removeFromUploadProgress(uploadCardItem);
        uploadCardItem.uploadStatus.status = UPLOAD_STATUS.ERROR
        uploadCardItem.uploadStatus.spinnerMode = 'indeterminate'
        uploadCardItem.uploadStatus.progressMsg = 'Error '
        uploadCardItem.uploadStatus.uploadTaskLink.cancel();
        uploadCardItem.uploadStatus.fileLink = file;

        if(environment.showServicePageAndShowDebugData) {
        this.utilityService.uploadServiceNotification(`Upload error: ${error}`, 10000);
        this.rollbar.error(`Upload error ${this.settingService.getOriginalUidFromLocalstorage()} ${error}`);
        // Your connection is unstable, please try again;

        }
        // Handle unsuccessful uploads
      },
      () => {

        this.handleModelProcessing(uploadCardItem);
      }
    );
  }

  private handleModelProcessing(uploadCardItem: IGroupedCard): void {

    const envLoadStatus = environment.type === 'mobile' ? 'loadStatusMobile' : 'loadStatusWeb';
    const envWarning = environment.type === 'mobile' ? 'warningMobile' : 'warningWeb';
    const envProgress = environment.type === 'mobile' ? 'loadProgressMobile' : 'loadProgressWeb';

    console.log('uploadFileName: ', uploadCardItem.fileName)
    const modelDataHandler = async (updateCard: UploadModelCard, unsubscribeHandler) => {
      if(updateCard?.[envLoadStatus]) {
        const calculatedPercent = 10 + 0.9*parseInt(updateCard[envProgress], 10);
        uploadCardItem.uploadStatus.progress = calculatedPercent
        console.log(updateCard[envProgress]);
        this.countTotalUploadProgress({
          itemId: uploadCardItem.id,
          progress: calculatedPercent,
          bytesTransfered: 0,
          bytesTotal: 0
        })
      }

      if(updateCard?.cardPosterMobile) {
        uploadCardItem.previewImageMobile = updateCard.cardPosterMobile;
      }

      if(updateCard?.cardPosterWeb) {
        uploadCardItem.previewImageWeb = updateCard.cardPosterWeb;
      }

      if(updateCard?.[envWarning]?.toLowerCase() === 'CheckSubscriptionError'.toLowerCase()) {
        console.warn('CheckSubscriptionError ', updateCard.id);
        this.rollbar.error(`CheckSubscriptionError ${this.settingService.getOriginalUidFromLocalstorage()}`);
        this.cardService.setSubForStatusSubscription();
      }

      if(updateCard?.[envLoadStatus]?.toLowerCase() === 'ParallelLimitError'.toLowerCase()) {
        this.rollbar.error(`PARALLEL LIMIT ERROR!!! ${this.settingService.getOriginalUidFromLocalstorage()}`);
        console.error('PARALLEL LIMIT ERROR!!! ')
        this.abortUploadToFirebase(uploadCardItem);

        this.cardService.setSubForStatusSubscription();

        if(environment.production === false) this.utilityService.uploadServiceNotification(`Parallel upload error`, 5000);

      }

      if(updateCard?.[envLoadStatus]?.toLowerCase() === 'TrialLimitReached'.toLowerCase()) {
        console.warn('TRIAL LIMIT REACHED!!! ');
        this.rollbar.error(`TRIAL LIMIT REACHED !!! ${this.settingService.getOriginalUidFromLocalstorage()}`);
        this.abortUploadToFirebase(uploadCardItem);
        this.cardService.setSubForStatusSubscription();
        this.uploadErrorSubscription.next();

        this.utilityService.uploadServiceNotification(`Your upload limit reached, please subscribe or contact support`, 5000);
      }

      if(updateCard?.[envLoadStatus]?.toLowerCase() === 'UserTrialExpirationError'.toLowerCase()) {
        console.warn('TRIAL LIMIT REACHED!!! ');
        this.rollbar.error(`TRIAL LIMIT REACHED !!! ${this.settingService.getOriginalUidFromLocalstorage()}`);
        this.abortUploadToFirebase(uploadCardItem);
        this.cardService.setSubForStatusSubscription();
        this.uploadErrorSubscription.next();

        this.utilityService.uploadServiceNotification(`Your upload limit reached, please subscribe or contact support`, 5000);
      }

      if(updateCard?.[envLoadStatus]?.toLowerCase() === 'UserExpirationError'.toLowerCase()) {
        console.warn('SUBSCRIBTION EXPIRED!!! ');
        this.rollbar.error(`SUBSCRIBTION EXPIRED !!! ${this.settingService.getOriginalUidFromLocalstorage()}`);
        this.abortUploadToFirebase(uploadCardItem);
        this.cardService.setSubForStatusSubscription();
        this.uploadErrorSubscription.next();

        this.utilityService.uploadServiceNotification(`Your subscription expired`, 5000);
      }

      if(updateCard?.[envLoadStatus]?.toLowerCase() === 'generalError'.toLowerCase()) {
        // this.rollbar.error(`GENERAL ERROR !!! ${this.settingService.getOriginalUidFromLocalstorage()}`);
        // this.abortUploadToFirebase(uploadCardItem);
        // this.cardService.setSubForStatusSubscription();
        // if(environment.production === false) this.utilityService.uploadServiceNotification(`General upload error, please contact support`, 5000);
        this.utilityService.rollbarErrorThrottlerForModel(updateCard?.[envLoadStatus], updateCard.id, uploadCardItem.details);
        uploadCardItem.details = 'General error: ' + updateCard?.[envWarning];
        uploadCardItem.uploadStatus.status = UPLOAD_STATUS.ERROR;
      }

      if(updateCard?.[envLoadStatus]?.toLowerCase() === 'renderModelError'.toLowerCase()) {
        // this.rollbar.error(`renderModelError ${updateCard[envWarning]} ${this.settingService.getOriginalUidFromLocalstorage()}`);
        // this.abortUploadToFirebase(uploadCardItem);
        // this.cardService.setSubForStatusSubscription();
        // if(environment.production === false) this.utilityService.uploadServiceNotification(`Rendering error: mtlNotFound \n ${updateCard[envWarning]}`, 5000);
        this.utilityService.rollbarErrorThrottlerForModel(updateCard?.[envLoadStatus], updateCard.id, uploadCardItem.details);
        uploadCardItem.details = 'Render model error: ' + updateCard?.[envWarning];
        uploadCardItem.uploadStatus.status = UPLOAD_STATUS.ERROR;

      }

      if(updateCard?.[envLoadStatus]?.toLowerCase() === 'UserRenderLimitTriggered'.toLowerCase()) {
        this.rollbar.error(`UserRenderLimitTriggered ${this.settingService.getOriginalUidFromLocalstorage()}`);
        this.abortUploadToFirebase(uploadCardItem);
        this.cardService.setSubForStatusSubscription();
        this.uploadErrorSubscription.next();
        this.utilityService.uploadServiceNotification(`Your render limit reached, please increase limit or contact support`, 5000);
      }


      if(updateCard?.[envLoadStatus]?.toLowerCase() === 'finished'.toLowerCase()) {
        uploadCardItem.title = this.utilityService.removeTimestampFromString(uploadCardItem.title);
        // this.removeModelInProcessList(uploadCardItem.fileName);
        uploadCardItem.uploadStatus.status = UPLOAD_STATUS.UPLOADED
        uploadCardItem.uploadStatus.progressMsg = 'Uploaded';
        uploadCardItem.previewVideoMobile = updateCard.cardContentMobile;
        uploadCardItem.previewVideoWeb = updateCard.cardContentWeb;
        this.latestUploadedModelSubscription.next(uploadCardItem);


        const uploadingCardsNow = this.uploadModels.filter((groupedCard) => {return groupedCard.uploadStatus.status === UPLOAD_STATUS.INPROGRESS || groupedCard.uploadStatus.status === UPLOAD_STATUS.PROCESSING});
        if(uploadingCardsNow.length === 0) {
          console.log('reset total progress');
          this.firebaseUploadingItem = [];
          this.itemsTotalProgressSubject.next(0);
        }
        (await (unsubscribeHandler))();
      }
    }

    uploadCardItem.uploadStatus.status = UPLOAD_STATUS.PROCESSING
    uploadCardItem.uploadStatus.progressMsg = 'Processing...'

    console.log('uploadFile - getFirebaseModelsData ', uploadCardItem);

    this.firebaseAuth.getFirebaseModelsData(modelDataHandler.bind(this), uploadCardItem);

  }




  public abortUploadToFirebase(cardSource: IGroupedCard): void {
    cardSource?.uploadStatus?.uploadTaskLink?.cancel();
    const cardToDelIndex = this.uploadModels.findIndex((el) => {
      return el.id === cardSource.id
    })
    this.uploadModels.splice(cardToDelIndex, 1);
    this.removeFromUploadProgress(cardSource);
    this.firebaseAuth.deleteDocumentsByIds([cardSource.id]);
    this.firebaseAuth.decreaseNumberOfUploads();
  }

  public abortCardRender(cardSource: IGroupedCard): void {
    this.cardService.stopRenderPostRequest(cardSource.id).then((res) => {

        const cardToDelIndex = this.uploadModels.findIndex((el) => {
          return el.id === cardSource.id
        })
        this.uploadModels.splice(cardToDelIndex, 1);
        this.removeFromUploadProgress(cardSource);
        this.firebaseAuth.deleteDocumentsByIds([cardSource.id]);
        this.firebaseAuth.decreaseNumberOfUploads();


    }).catch((err) => {
      console.error(err);
      this.firebaseAuth.deleteDocumentsByIds([cardSource.id])
    });

  }

  private countTotalUploadProgress(uploadItem?: IUploadProgress): void {
    if (uploadItem) {
      const currentUploadItemIndex = this.firebaseUploadingItem.findIndex((item) => {
        return uploadItem.itemId === item.itemId
      });
      if (currentUploadItemIndex >= 0) {
        this.firebaseUploadingItem.splice(currentUploadItemIndex, 1, uploadItem);
      } else {
        this.firebaseUploadingItem.push(uploadItem);
      }
    }

    const uploadLength = this.firebaseUploadingItem.length;
    let totalProgress = 0;
    if (uploadLength > 0) {
      totalProgress = this.firebaseUploadingItem.reduce((prev, current) => {
        // Ensure progress is a finite number, defaulting to 0 if invalid.
        const safeProgress = Number.isFinite(current.progress) ? current.progress : 0;
        return prev + safeProgress;
      }, 0) / uploadLength;
    }
    this.itemsTotalProgressSubject.next(totalProgress);
    console.log('uploadingItemsTotalProgress:', totalProgress);
  }

  private removeFromUploadProgress(card: IGroupedCard): void {
    const currentUploadItemIndex = this.firebaseUploadingItem.findIndex((item)=>{return card.id === item.itemId});
    this.firebaseUploadingItem.splice(currentUploadItemIndex, 1);
    this.countTotalUploadProgress();
  }


  // UPLOAD CARD HANDLERS

  public async stopCardUpload(cardSource: IGroupedCard): Promise<void> {
    if (cardSource.uploadStatus?.uploadTaskLink) {
      // TODO: RETURN TO QUESTION ABOUT HANDLING STOPPED CARDS ON BACKEND, WHEN WE WILL GET PERSONAL LIBRARY FOR EACH USER;

      cardSource.uploadStatus.status = UPLOAD_STATUS.STOPPED;
      cardSource.uploadStatus.uploadTaskLink.pause();
      const isUploadResumed = await this.showStopUploadCardSnackBarDialog(cardSource);
      if (isUploadResumed) {
         cardSource.uploadStatus.status = UPLOAD_STATUS.INPROGRESS;
        cardSource.uploadStatus.uploadTaskLink.resume();
      } else {
        this.abortUploadToFirebase(cardSource);
      }
    }
  }

  public async stopCardRender(cardSource: IGroupedCard): Promise<void> {
    console.log('stopCardRender TRIGGERED');

    const isUploadResumed = await this.showStopRenderCardSnackBarDialog(cardSource);
    if (isUploadResumed) {

    } else {
      console.log(`stopCardRender ${cardSource.title} ABORTED`)
      this.abortCardRender(cardSource);
    }
  }

  public retryUploadToFirebase(retryUploadEvent: IUploadCardEvent): void {
    this.startFirebaseFileUploadInternal(retryUploadEvent);
  }

  public retryCardUpload(cardSource: IGroupedCard) {
    if(cardSource.uploadStatus?.uploadTaskLink)  {
      const trigeredEvent: IUploadCardEvent = {
        file: cardSource.uploadStatus.fileLink,
        cardId: cardSource.id
      }
      this.retryUploadToFirebase(trigeredEvent)
    }
  }

  public showStopUploadCardSnackBarDialog(cardToStop: IGroupedCard): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const snack = this.utilityService.uploadServiceNotification(`Uploading stopped`, 4000, 'Cancel');

      snack.onAction().subscribe(()=>{
        resolve(true)
      })
      setTimeout(() => {
        resolve(false);
      }, 4500);
    });
  }

  public showStopRenderCardSnackBarDialog(cardToStop: IGroupedCard): Promise<boolean> {
    return new Promise((resolve, reject) => {

      const snackRef = this.utilityService.uploadServiceNotificationFromComponent('Rendering stopped', 4000, 'Cancel', StopRenderSnackBarComponent);

      snackRef.instance.actionTaken.subscribe((action) => {
        if (action) {
          resolve(false);
        }
      });

      snackRef.instance.cancelled.subscribe(() => {
        snackRef.dismiss(); // Dismiss the snackbar when "Cancel" is clicked
        resolve(true); // Resolve the promise as false
      });
    });
  }

 // SHOULD BE UPDATED CODE BASE BELOW :

  async pickAndUploadFileNativeIos(action: INavpanelAction) {
    this.processFileUpload(action);
  }

  async pickAndUploadFileNative() {
    this.processFileUpload();
  }

  private async processFileUpload(action?: INavpanelAction) {
    try {
      const file = await this.utilityService.pickFile();
      if (file) {
        console.log('webAPIFile:', file);
        const resultFilename = file.name.slice(0, file.name.lastIndexOf('.'));
        this.setLastFileToUploadName(resultFilename);
        this.requestFileUpload(file, action?.optionalData);
      }
    } catch (error) {
      console.error('Error picking and uploading file:', error);
      this.utilityService.showErrorSnackBar('Failed to pick and upload file. Please try again.', 3000);
    }
  }

  // UTILITY

  public setLastFileToUploadName(name: string): void {
    const filePostfix = new Date().getTime().toString() + environment.uploadFilePostfix;
    this.lastFileName = name.replace(/\s+/g, '') + filePostfix;
  }

  public getLastFileName(): string {
    return this.lastFileName;
  }

  private renderLimitCheckNotPassed(): boolean {
    return this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER && (this.firebaseAuth.currentUser.renderModelsPerCycle + this.currentNumberOfUploadingOrRenderingModels) >= this.firebaseAuth.currentUser.renderLimit;
  }

}

  // async pickAndUploadFileNativeIos (action: INavpanelAction) {
  //   const file = await this.pickFileNative()
  //   console.log('file selected', file.name);
  //   console.log('file selected', file.size);

  //   const filename = file.name;
  //   const resultFilename = filename.slice(0, filename.lastIndexOf('.'));
  //   this.setLastFileToUploadName(resultFilename);
  //   this.requestFileUpload(file, action.optionalData, undefined);

  // }

  // async pickFileNative(): Promise<File> {
  //   try {
  //     // Вызовите file picker с фильтрацией по расширению
  //     const result = await FilePicker.pickFiles({
  //       multiple: false,
  //       types: ['model/vnd.pixar.usdz', 'model/vnd.usdz+zip'],
  //       readData: true,

  //     });

  //     if (result && result.files && result.files.length > 0) {
  //       const file = result.files[0];
  //       const webAPIFile = this.utilityService.createWebAPIFile(file);

  //       if (webAPIFile) {
  //         return webAPIFile;
  //       } else {
  //         console.error('Failed to create Web API File object');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error picking file:', error);
  //   }
  // }

  // async pickAndUploadFileNative() {
  //   try {
  //     // Вызовите file picker с фильтрацией по расширению
  //     const result = await FilePicker.pickFiles({
  //       multiple: false,
  //       types: ['model/vnd.pixar.usdz', 'model/vnd.usdz+zip'],
  //       readData: true,

  //     });

  //     if (result && result.files && result.files.length > 0) {
  //       const file = result.files[0];
  //       const webAPIFile = this.utilityService.createWebAPIFile(file);

  //       if (webAPIFile) {
  //         // Загрузите файл в Firebase
  //         // await this.uploadFileToFirebase(webAPIFile);
  //         console.log('webAPIFile:', webAPIFile);
  //         const filename = webAPIFile.name;
  //         const resultFilename = filename.slice(0, filename.lastIndexOf('.'));
  //         this.setLastFileToUploadName(resultFilename);
  //         this.requestFileUpload(webAPIFile, undefined, resultFilename);
  //       } else {
  //         console.error('Failed to create Web API File object');
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error picking file:', error);
  //   }
  // }




// public _setErrorOnUploadCard(card: IGroupedCard): void {
//   this.removeFromUploadProgress(card);
//   const uploadCardItem = this.uploadModels.find((item) => {
//     return item.id === card.id
//   })
//   uploadCardItem.uploadStatus.status = UPLOAD_STATUS.ERROR
//   uploadCardItem.uploadStatus.spinnerMode = 'indeterminate'
//   uploadCardItem.uploadStatus.progressMsg = 'Error '
//   uploadCardItem.uploadStatus.uploadTaskLink.cancel();
// }











// public isUploadingOrProcessingNow3inRow(): boolean {
//   return this.uploadModels.filter(item => item?.uploadStatus?.status === UPLOAD_STATUS.INPROGRESS || item?.uploadStatus?.status === UPLOAD_STATUS.PROCESSING).length > 2;
// }

// public isUploadingOrProcessingNow(): boolean {
//   return this.uploadModels.filter(item => item?.uploadStatus?.status === UPLOAD_STATUS.INPROGRESS || item?.uploadStatus?.status === UPLOAD_STATUS.PROCESSING).length > 0;
// }

// private formatBytes(bytes, decimals = 2) {
//   if (bytes === 0) return '0 Bytes';

//   const k = 1024;
//   const dm = decimals < 0 ? 0 : decimals;
//   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

//   const i = Math.floor(Math.log(bytes) / Math.log(k));

//   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
// }

  // if(this.currentProcessingModelsChecked === false) {
  //   this.currentProcessingModelsChecked = true;
  //   const processModels = await firebaseAuth.getDocumentsWithLoadStatusNotFinished();
  //   if(processModels.length > 0) {
  //     processModels.forEach((modelInProcessing) => {
  //       const mockItem = this.generateMockedUploadItem();
  //       mockItem.fileName = modelInProcessing;
  //       mockItem.id = modelInProcessing;
  //       this.uploadModels.unshift(mockItem);
  //       this.handleModelProcessing(mockItem);
  //     })
  //   }
  // }

 // public getIsUploadDisabled(): boolean {
    // if(this.firebaseAuth.currentUser.subscriptionStatus === SUBSCRIPTION_STATUS.SUBSCRIBER && this.uploadModels.length > 2)
    // {
    //   return this.isUploadingOrProcessingNow3inRow();
    // }
    // else {
    //   return this.isUploadingOrProcessingNow();
    // }
  // }

  // async uploadFileToFirebase(file: globalThis.File) {
  //   try {
  //     // Создайте ссылку на файл в Firebase Storage
  //     const storageRef = await this.firebaseAuth.getStorageRef();
  //     const fileRef = storageRef.child(`your-folder/${file.name}`);

  //     // Загрузите File в Firebase Storage
  //     const uploadTaskSnapshot = await fileRef.put(file);
  //     const downloadUrl = await uploadTaskSnapshot.ref.getDownloadURL();

  //     console.log('File uploaded:', downloadUrl);
  //   } catch (error) {
  //     console.error('Error uploading file:', error);
  //   }
  // }

    // private getProcessingModelsFromLocalStorage(): string[] {
  //   let currentProcessingModels: string[] = JSON.parse(localStorage.getItem(LOCALSTORAGE_VAR.MODELS_CURRENT_PROCESSING));
  //   if (currentProcessingModels === undefined || currentProcessingModels === null) {
  //     localStorage.setItem(LOCALSTORAGE_VAR.MODELS_CURRENT_PROCESSING, JSON.stringify([]));
  //     currentProcessingModels = [];
  //   }
  //   return currentProcessingModels
  // }

  // private saveModelInProssingList(modelFileName: string): void {
  //   const getProcessingModelsFromLocalStorage = this.getProcessingModelsFromLocalStorage();
  //   if( getProcessingModelsFromLocalStorage.findIndex(savedModel => savedModel === modelFileName) === -1) {
  //     getProcessingModelsFromLocalStorage.push(modelFileName);
  //     localStorage.setItem(LOCALSTORAGE_VAR.MODELS_CURRENT_PROCESSING, JSON.stringify(getProcessingModelsFromLocalStorage));
  //     console.log('model saved in localstorage processing list: ', JSON.parse(localStorage.getItem(LOCALSTORAGE_VAR.MODELS_CURRENT_PROCESSING)))
  //   }
  //   else {
  //     console.error('something wrong with saving processing model in localstorage')
  //   }
  // }

  // private removeModelInProcessList(modelFileName: string): void {
  //   const getProcessingModelsFromLocalStorage = this.getProcessingModelsFromLocalStorage();
  //   const modelToRemoveIndex = getProcessingModelsFromLocalStorage.findIndex(savedModel => savedModel === modelFileName);
  //   if(modelToRemoveIndex > -1) {
  //     getProcessingModelsFromLocalStorage.splice(modelToRemoveIndex, 1);
  //     localStorage.setItem(LOCALSTORAGE_VAR.MODELS_CURRENT_PROCESSING, JSON.stringify(getProcessingModelsFromLocalStorage));
  //     console.log('model removed from localstorage processing list: ', JSON.parse(localStorage.getItem(LOCALSTORAGE_VAR.MODELS_CURRENT_PROCESSING)))
  //   }
  //   else {
  //     console.error('something wrong with removing processing model from localstorage');
  //     console.log('modelToRemove: ', modelFileName);
  //     console.log(this.getProcessingModelsFromLocalStorage())
  //   }
  // }

