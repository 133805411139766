import { Component, Inject, AfterViewInit, Renderer2, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

export interface DialogData {
  dialogTitle: string;
  dialogText: string;
  confirmBtntext?: string;
  declineBtnText?: string;
}



@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: 'confirmation-dialog.component.html',
  styleUrls: ['confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements AfterViewInit {
  public isMobile: boolean = environment.type === 'mobile';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    this.data.confirmBtntext = this.data.confirmBtntext || 'Yes';
    this.data.declineBtnText = this.data.declineBtnText || 'No';
  }

  ngAfterViewInit() {
    const overlayPane = this.elementRef.nativeElement.closest('.cdk-overlay-pane');
    if (overlayPane && this.isMobile) {
      this.renderer.addClass(overlayPane, 'is-mobile');
    } else {
      this.renderer.addClass(overlayPane, 'is-desktop');
    }
  }
}
