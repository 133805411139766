import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface iconNotificationPopupData {
  matIconName: string;
  notificationText: string;
}

@Component({
  selector: 'app-popup-icon-notification',
  templateUrl: './popup-icon-notification.component.html',
  styleUrls: ['./popup-icon-notification.component.scss']
})
export class PopupIconNotificationComponent {
  public matIconName: string = 'checkbox-thin-icon';
  public notificationText: string = 'Copied to clipboard';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: iconNotificationPopupData,
    private dialogRef: MatDialogRef<PopupIconNotificationComponent>
  ) {
    setTimeout(() => this.dialogRef.close(), 150000);
  }
}
