<mat-dialog-actions align="center" class="vertical-buttons">
  <!-- <button *ngIf="!isUserAuthenticated"  [hidden]="!authService.isBasicTimeoutPassed"  class="sign-in-with-apple opacitybtn" (click)="onSignInWithAppleClicked()">
    <svg (click)="onSignInWithAppleClicked()" class="apple-logo" width="22" height="22" viewBox="0 0 22 22"
      fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0294 1.91442C13.5188 0.00928785 15.5895 0 15.5895 0C15.5895 0 15.8975 1.79114 14.4178 3.51659C12.8379 5.35898 11.0421 5.0575 11.0421 5.0575C11.0421 5.0575 10.7049 3.60853 12.0294 1.91442ZM11.2316 6.31219C11.9978 6.31219 13.4199 5.29003 15.2709 5.29003C18.4571 5.29003 19.7106 7.4903 19.7106 7.4903C19.7106 7.4903 17.2591 8.70672 17.2591 11.6583C17.2591 14.9879 20.313 16.1354 20.313 16.1354C20.313 16.1354 18.1782 21.9668 15.2946 21.9668C13.9702 21.9668 12.9406 21.1007 11.5451 21.1007C10.123 21.1007 8.71182 21.9991 7.79269 21.9991C5.15956 21.9992 1.83301 16.4675 1.83301 12.021C1.83301 7.64625 4.64872 5.35132 7.28974 5.35132C9.00665 5.35132 10.339 6.31219 11.2316 6.31219Z"
        fill="black" />
    </svg>
    <span>Continue with Apple</span>
  </button> -->
  <button *ngIf="!isUserAuthenticated" [hidden]="!authService.isBasicTimeoutPassed" class="sign-in-with-google opacitybtn" (click)="onSignInWithGoogleClicked()">
    <svg xmlns="http://www.w3.org/2000/svg" class="google-logo"  height="22" viewBox="0 0 24 24" width="22"><path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/></svg>
    <span>Continue with Google</span>
  </button>
  <button *ngIf="!isUserAuthenticated && isDev" [hidden]="!authService.isBasicTimeoutPassed"  (click)="onSignInWithEmailClicked()" class="sign-in-with-email opacitybtn" >
    <svg class="email-logo" xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 24 24" width="22">
      <path d="M12 12.713L.015 3h23.97L12 12.713zM12 14.828L0 5v14h24V5L12 14.828z" fill="#000000"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
    <span>Sign In with Email</span>
  </button>
  <div class="login-container" *ngIf="ifEmailLoginShown && !isUserAuthenticated">
    <h2>Login</h2>
    <form (ngSubmit)="onSubmitLoginFormClicked()">
      <div>
        <label for="email">Email:</label>
        <input type="email" id="email" [(ngModel)]="email" name="email" required>
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" id="password" [(ngModel)]="password" name="password" required>
      </div>
      <button type="submit">Login</button>
    </form>
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
  <p class="terms-text-disclaimer">
    <span>By continuing you agree to our</span>
    <u><a href="/legal/terms-of-service">Terms of Service</a></u> <span>&nbsp;and&nbsp;</span> <u><a href="/legal/privacy-policy">Privacy Policy</a></u><span>.</span>
  </p>

</mat-dialog-actions>
